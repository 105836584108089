import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import loaderIview from "@/utils/plugin/loader-IView-component";
import loaderFilters from "@/utils/filters/loader-filters";
import loaderComponents from "@c/loader-components.js";
import loaderDirectives from '@/directives/loader-directives'
// import loaderEcharts from '@/utils/plugin/loader-echarts'
import one from './one-builder'
import '@design/iview-theme/index.less';
import '@design/gok/index.less';
import "swiper/css/swiper.min.css"
import { wangEditorFormat } from './utils/common/index.js'

Vue.config.productionTip = false;
// ---------------自动加载脚本区---------------
// 自动加载指令
loaderDirectives(Vue)
// 自动加载iview组件
loaderIview(Vue);
// 自动加载过滤器
loaderFilters(Vue)
// 自动加载全局组件
loaderComponents.install(Vue)
// 加载Echarts
// loaderEcharts()
// -------------------------------------------

// ---------------原型链---------------
// 事件总线
Vue.prototype.$bus = new Vue();
Vue.prototype.$wangEditorFormat = wangEditorFormat;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
