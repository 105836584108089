<!--
 * @Author: shiyihuang 1023661987@qq.com
 * @Date: 2023-02-16 14:15:23
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-02-20 14:59:15
 * @FilePath: /gok-portal/src/components/gok-components/gok-collapse.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="collapse-container" :style="containerStyle" v-show="list.length">
    <div class="first-item" v-for="item in list" :key="item.id">
      <p class="first-title" @click="firstSectionClick(item)">
        <span class="arrow-icon" :style="iconStyle(item.id, item.list.length)"></span>
        <span :title="item.name" class="title-text" :class="activeClass(item.id)">{{item.name}}</span>
      </p>
      <div class="section-item" :class="openFlag(item.id)">
        <p class="section-title" :title="childItem.name" :class="activeClass(childItem.id)" v-for="childItem in item.list" :key="childItem.id" @click="sectionItemClick(childItem, item)">{{childItem.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: '230px'
    },
    list: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      openKeys: [],
      activeItem: {
        levelFirst: '',
        levelSecond: ''
      }
    }
  },
  computed: {
    containerStyle () {
      return {
        width: this.width
      }
    },
    openFlag () {
      return (key) => {
        return this.openKeys.includes(key) ? 'open-section-item' : '';
      }
    },
    iconStyle () {
      return (key, flag = true) => {
        const _90deg = `transform: rotate(90deg);
                        -webkit-transform: rotate(90deg);
                        -moz-transform: rotate(90deg);
                        -ms-transform: rotate(90deg);
                        -o-transform: rotate(90deg);
                        transition: 500ms
                        -webkit-transition: 500ms
                        -moz-transition: 500ms
                        -ms-transition: 500ms
                        -o-transition: 500ms`,
          _0deg = `transform: rotate(0);
                  -webkit-transform: rotate(0);
                  -moz-transform: rotate(0);
                  -ms-transform: rotate(0);
                  -o-transform: rotate(0);
                  transition: 500ms
                  -webkit-transition: 500ms
                  -moz-transition: 500ms
                  -ms-transition: 500ms
                  -o-transition: 500ms`;
        if (Boolean(flag)) {
          return this.openFlag(key) ? _90deg : _0deg;
        } else {
          return '';
        }
      }
    },
    activeClass () {
      return (key) => {
        if (this.activeItem.levelSecond) return this.activeItem.levelSecond === key ? 'active-item' : '';
        return this.activeItem.levelFirst === key ? 'active-item' : '';
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init () {
      let firstItem = {}, secondItem = {};
      if (this.open) {
        this.list.forEach(item => {
          this.openKeys.push(item.id);
        })
        if (!firstItem?.id) firstItem = this.list[0];
        if (firstItem?.id) this.firstSectionClick(firstItem, !secondItem?.id);
      } else {
        this.list.forEach(item => {
          if (item.active) firstItem = item;
          if (item.list.length) {
            item.list.forEach(childItem => {
              if (childItem.active) secondItem = childItem;
            })
          }
        })
        if (!firstItem?.id) firstItem = this.list[0];
        if (firstItem?.id) this.firstSectionClick(firstItem, !secondItem?.id);
        if (secondItem?.id) this.sectionItemClick(secondItem);
      }
    },
    firstSectionClick (item = {}, emitFlag = true) {
      if (this.openFlag(item.id)) {
        const newSet = new Set(this.openKeys);
        newSet.delete(item.id);
        this.openKeys = [...newSet];
      } else {
        this.openKeys.push(item.id);
      }
      this.activeItem.levelFirst = item.id;
      this.activeItem.levelSecond = '';
      emitFlag && this.$emit('change', {...this.activeItem, ...item});
    },
    sectionItemClick (item, parentItem = {}, emitFlag = true) {
      this.activeItem.levelFirst = parentItem.id;
      this.activeItem.levelSecond = item.id;
      emitFlag && this.$emit('change', {...this.activeItem, ...item});
    }
  }
}
</script>
<style lang="less" scoped>
.title-base {
  display: flex;
  align-items: center;
  height: 36px;
  background: #FFFFFF;
  border-radius: 2px;
}
.collapse-container{
  padding: 8px 16px;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  background: #FFFFFF;
  .first-item{
    cursor: pointer;
    .first-title{
      &:extend(.title-base);
      .arrow-icon{
        // transform: rotate(90deg);
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background: url('https://gok-static.obs.cn-east-2.myhuaweicloud.com/portal/images/icon/triangle-arrow.png') no-repeat center;
        background-size: contain;
      }
      .title-text{
        flex: 1;
        .g-text-overflow(1);
      }
    }
  }
  .section-item{
    position: relative;
    padding-left: 24px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s;
    &:before{
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 12px;
      z-index: 10;
      border-left: solid 1px #E5E6EB;
    }
    .section-title{
      &:extend(.title-base);
      position: relative;
      padding-left: 28px;
      line-height: 36px;
      .g-text-overflow(1);
      &:before{
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 6px;
        width: 12px;
        height: 12px;
        margin-top: -6px;
        background: url('https://gok-static.goktech.cn/portal/images/row-blue-dot.png') no-repeat center;
        background-size: contain;
      }
    }
  }
  .open-section-item{
    max-height: 400px;
    transition: max-height .5s;
  }
  .active-item{
    color: #165DFF;
  }
}
</style>
