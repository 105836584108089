/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-05 21:40:55
 * @LastEditors: lpj 1248708823@qq.com
 * @LastEditTime: 2022-09-05 21:48:41
 * @FilePath: \gok-portal\src\utils\filters\loader-filters.js
 * @Description: 自动加载全局过滤器
 */
import * as FILTER from './index.js'; // 全局过滤器
/**
 * @description: 自动加载全局过滤器
 * @param {*} Vue
 * @return {*}
 */
export default function loaderFilters(Vue) {
  Object.keys(FILTER).forEach(key => {
    Vue.filter(key, FILTER[key])
  })
}