/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-05 22:20:18
 * @LastEditors: lpj 1248708823@qq.com
 * @LastEditTime: 2022-09-05 22:33:18
 * @FilePath: \gok-portal\src\directives\index.js
 * @Description: 自动加载指令
 */
import directive from './directives'

const loaderDirectives = Vue => {
  Object.keys(directive).forEach(key => {
    Vue.directive(key, directive[key])

  })
}

export default loaderDirectives
