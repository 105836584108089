/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-19 09:19:13
 * @LastEditors: lpj 1248708823@qq.com
 * @LastEditTime: 2022-09-23 15:07:54
 * @FilePath: \gok-portal\src\directives\modules\preview.js
 * @Description: 预览 目前只支持图片
 */
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';
import { Modal, Message } from 'view-design'
import {getDocInfo} from '@api/file/index'
const CONTAINERID = 'GOK_FILE_PREVIEW';
const IMG_TYPE = ['png', 'jpg', 'jpeg', 'svg', 'bmp', 'gif', 'webp', 'bmp']; // 图片类型
import {fileFormat} from '@/utils/common/index'
// 获取预览类型
const CHECK_PREVIEW_TYPE = (type) => {
  if (IMG_TYPE.includes(type)) return 'img';
  return 'err';
}
let VIEWER_OBJ = null; // 预览对象
// 预览对象
const PREVIEW = {
  // 图片预览
  img: (el, bindValue, el_type, trackObj, modifiers = {}) => {
    // getDocInfo(bindValue.docId).then(res => {
      // const data = res.data;
      let DOM = {};
      // 如果是图片
      if (el_type === 'img') {
        DOM = el;
      } else {
        // 如果不是图片，创建一个图片
        DOM = document.createElement('img');
        DOM.src = bindValue.url;
        DOM.style.display = 'none';
        el.append(DOM);
      }
      let TOOL_BAR = {};
      // if (ONE.context.version === 'pc:teaching:pc:1.0') {
        TOOL_BAR = {
          zoomIn: 1,
          zoomOut: 1,
          oneToOne: 1,
          reset: 1,
          prev: 0,
          play: 0,
          next: 0,
          rotateLeft: 1,
          rotateRight: 1,
          flipHorizontal: 1,
          flipVertical: 1
        }
      // }
      // viewer 配置信息
      const VIEWER_OPTIONS = {
        // inline: true,
        line: Boolean(modifiers.inline),
        title: false,
        toolbar: false,
        navbar: false,
        zIndex: 1000,
        backdrop: !modifiers.inline,
        toolbar: TOOL_BAR,
        fullsreen: true,
        show: () => {
          const viewerBox = document.querySelectorAll('.viewer-toolbar');
          const customerDownloadLi = document.querySelector('.customer-download');
          const viewerUl = viewerBox[viewerBox.length - 1].querySelector('ul');
          // const downloadLi = document.createElement('li');
          // if (customerDownloadLi) {
          //   customerDownloadLi.remove();
          // }
          // downloadLi.style.backgroundImage = "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAM5JREFUOE/Vk70NwjAUhL9bgg0QiBbRk4oJiFiCkp6eEdiASBRUdKGnR2IGhjjkyEH5EwRR8RpLtu/zvbMtfiz9qOctwPY2HCCpGLvqEyCPgORPALHnsaRVsGy71oLtA3CrZlLLwPYUOAFHSesqIIqXQCopKzNphWh7AZyBIbCPGx9AS1wE3JWu7ZGke+kAmDdPbjmIFneSruViBNTEtmfARlJac2DbQCLpUgFMgEFjLgBzSYX7VwsloOfT7gSEKwpB9ams1UIf1dd/oQ/0CcYXYBFm70wBAAAAAElFTkSuQmCC')";
          // downloadLi.style.backgroundPosition = 'center';
          // downloadLi.style.backgroundRepeat = 'no-repeat';
          // // downloadLi.setAttribute('class', 'customer-download');
          // viewerUl.appendChild(downloadLi);
          // downloadLi.onclick = () => {
          //   // 后面有下载需求在补 TODO:
          //   // FILE_DOWNLOAD(data.url, bindValue.name);
          // }
          if (trackObj.track && bindValue.status) {
            LOG_PREVIEW.START(bindValue, VIEWER_OBJ);
          }
        },
        hide: () => {
          const customerDownloadLi = document.querySelector('.customer-download');
          customerDownloadLi.remove();
          if (trackObj.track && bindValue.status) {
            LOG_PREVIEW.STOP();
          }
          if (el_type !== 'img') {
            // 如果原先不是图片则销毁创建的图片
            DOM.parentNode.removeChild(DOM);
          }
        }
      }
      // 初始化viewer
      VIEWER_OBJ = new Viewer(DOM, VIEWER_OPTIONS)
      VIEWER_OBJ.show();
      // gokBus.$off('previewClose');
      // gokBus.$on('previewClose', () => {
      //   VIEWER_OBJ.hide();
      // });
    }
    // )
    // .catch(err => {
    //   Message.error('文件信息获取失败');
    // })
  }
  // err: (el, bindValue, el_type, trackObj, modifiers = {}) => {
  //   // if (modifiers.download) {
  //   //   ONE.uc.http.get($API_TC.getDocInfo(bindValue.docId), {}).then(res => {
  //   //     FILE_DOWNLOAD(res.data.url, bindValue.name);
  //   //   })
  //   //   return
  //   // }
  //   Message.warning('该文件格式不支持预览，请下载后查看');
  //   // Modal.info({
  //   //   title: '提示',
  //   //   content: '该文件格式不支持预览，请下载后查看'
  //   // })
  // }
// }

/**
 * 关闭所有
 */
 const CLOSE_ALL = function () {
  const nodeList = document.querySelectorAll('#' + CONTAINERID);
  nodeList.forEach(item => {
    item.remove();
  })
}

export default {
  inserted: (el, binding, vnode) => {
    const BIND_VALUE = binding.value;
    const MODIFIERS = binding.modifiers;
    const PREVIEW_URL = BIND_VALUE.url;
    if (BIND_VALUE.intercept) return
    // 如果没有URL则不进行预览操作
    CLOSE_ALL();
    VIEWER_OBJ && VIEWER_OBJ.hide();
    // 申明埋点对象
    const TRACK_OBJ = {
      track: MODIFIERS.track
    }
    el.onclick = (e) => {
      // PREVIEW_URL = data.url;
      BIND_VALUE.url = PREVIEW_URL;
      const EL_TYPE = e.target;
      const PREVIEW_TYPE = fileFormat(BIND_VALUE.name.split('?')[0]);
      PREVIEW[CHECK_PREVIEW_TYPE(PREVIEW_TYPE)](el, BIND_VALUE, EL_TYPE, TRACK_OBJ, MODIFIERS);
    }
    if (MODIFIERS.auto) {
      el.click();
    }
  },
  update: (el, binding, vnode) => {
    if (binding.oldValue !== binding.value) {
      const BIND_VALUE = binding.value;
      const MODIFIERS = binding.modifiers;
      const PREVIEW_URL = BIND_VALUE.url;
      if (BIND_VALUE.intercept) return
      // 如果没有URL则不进行预览操作
      CLOSE_ALL();
      VIEWER_OBJ && VIEWER_OBJ.hide();
      // 申明埋点对象
      const TRACK_OBJ = {
        track: MODIFIERS.track
      }
      el.onclick = (e) => {
        BIND_VALUE.url = PREVIEW_URL;
        const EL_TYPE = e.target;
        const PREVIEW_TYPE = fileFormat(BIND_VALUE.name.split('?')[0]);
        PREVIEW[CHECK_PREVIEW_TYPE(PREVIEW_TYPE)](el, BIND_VALUE, EL_TYPE, TRACK_OBJ, MODIFIERS);
      }
      if (MODIFIERS.auto) {
        el.click();
      }
    }
  }
}
