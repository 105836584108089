<!--
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-06 14:34:11
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-01-17 09:18:52
 * @FilePath: \gok-portal\src\views\cooperation\components\banner\carousel.vue
 * @Description: 公共的分页器 保证设置一样。 外面访问的时候不需要带params了
-->
<template>
  <div class="page-wrapper">
    <div class="content">
      <slot> </slot>
    </div>
    <Page
      class-name="page"
      :total="Number(total)"
      size="small"
      :page-size="pageParams.pageSize"
      :style="`margin-top:${marginTop}px`"
      @on-change="change"
      @on-prev="change"
      @on-next="change"
    />
  </div>
</template>

<script>
export default {
  props: {
    marginTop: {
      default: 20
    },
    // 回调函数
    callBack: {
      default: () => {
        // console.log('回调搜索函数');
      },
      require: true
    },
    // params 必带pageNo、pageSize
    params: {
      require: true,
      default: () => {
        return {
          pageNo: 1,
          pageSize: 20
        }
      }
    },
    // 数据列表 双向改变
    dataList: {
      require: true,
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      total: 100,
      isLoading: false,
      pageParams: {
        pageNo: 0,
        pageSize: 20
      }
    }
  },
  methods: {
    async change(Number) {
      // console.log(Number)
      await this.handleLoader(Number)
    },
    /**
     * @description: 初始化调用
     * @return {*}
     */  
    async searchBase() {
       this.$emit(
        'update:dataList',
        []
      )
      await this.handleLoader(1)
    },
    /**
     * @description: 开始加载数据
     * @return {*}
     */
    async handleLoader(number = 1) {
      const oldPageNo =
        this.pageParams.pageNo
      if (this.isLoading) {
        return
      }

      let newDataList = []
      try {
        this.pageParams.pageNo = number
        Object.assign(
          this.params,
          this.pageParams
        )
        const data =
          await this.callBack(
            this.params
          )
        if (!data) {
          console.error(
            '返回值为空！,请检查该函数'
          )
        }
        // console.log(this.callBack(this.params));
        // 开始做拼接
        this.total = Number(data?.total)

        // console.log(this.total );
        newDataList = [].concat(
          data?.list
        )
      } catch (error) {
        console.error(error)
        // newDataList = this.dataList
      }
      // 暴露数据响应
      this.$emit(
        'update:dataList',
    newDataList
      )
      this.$emit(
        'update:params',
        this.params
      )
      this.$emit('total', this.total)
      this.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
// 修改分页器背景为透明色
/deep/.ivu-page-item {
  background: transparent;
}
/deep/ .ivu-page-prev {
  background-color: transparent;
}
/deep/.ivu-page-next {
  background-color: transparent;
}
</style>
