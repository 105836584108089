<template>
<div class="container flex-center flex-colum">
  <div class="friend-link" v-if="linkList.length">
    <p>友情链接</p>
    <div class="tag">
      <p v-for="item in linkList" :key="item.id">
        <a :href="item.linksUrl" target="_blank">{{item.title}}</a>
      </p>
    </div>
  </div>
  <div v-if="about.componentName === 'FzNetworkInfoAdmin'" v-html="$wangEditorFormat(contentHtml(about.baseDesc))"></div>
  <div>
    Copyright@ {{ about.copyRight }} 版权所有 <span class="cp" @click="handleClick">{{ about.recordNumber }}</span>
  </div>
</div>
</template>

<script>
import AboutModule from '@/store/modules/about/AboutModule'
import { textareaValue2Html } from '@utils/common/index'
export default {
  name: "gok-header",
  computed:{
    // ...mapGetters(['about'])
    about () {
      return AboutModule.about
    },
    linkList() {
      return AboutModule.linkList
    }
  },
  methods:{
    handleClick(){
      window.open('https://beian.miit.gov.cn/','_blank')
    },
    contentHtml(val) {
      if (!val) {
        return
      }
      return textareaValue2Html(val)
    }
  }
};
</script>

<style lang="less" scoped>
@base-footer-background:#171A3C;
.container{
  width: 100%;
  font-size: 14px;
  min-height: 77px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: @bg-white;
  background: @base-footer-background;
  padding: 15px 0;
  .cp{
    margin-left: 5px;
    &:hover{
      text-decoration: underline;
    }
  }
  .friend-link {
    text-align: center;
    .tag {
      display: flex;
      p {
        padding: 2px 10px;
        border: 1px solid #A2A3B1;
        margin: 10px 5px;
        border-radius: 4px 4px 4px 4px;
      }
      a{
        text-decoration:none; 
        color: #A2A3B1;
      }
    }
  }
}
</style>
