/*
 * @Author: shiyihuang 1023661987@qq.com
 * @Date: 2023-02-16 10:04:32
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-02-16 10:08:48
 * @FilePath: /src/utils/module/index.js
 * @Description: 模块名称
 */
// 设置模块名称
const MODULE_NAME = 'portalModuleName';
export const setModuleName = (value) => {
  localStorage.setItem(MODULE_NAME, value);
}
// 获取模块名称
export const getModuleName = () => {
  return localStorage.getItem(MODULE_NAME);
}
