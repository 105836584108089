import ScrollReveal from "scrollreveal";

const sr = ScrollReveal();

export default {
  inserted(el, binding) {
    const value = binding.value || {
      sequenceDelay: 100
    };

    const options = {
      reset: false,
      origin: 'bottom',
      duration: 500,
      distance: '150%',
      // delay: 0,
      // rotate: { x: 0, y: 0, z: 0 },
      opacity: 0,
      // scale: 2,
      easing: 'ease-in-out',
    }

    sr.reveal(el.childNodes, options, value.sequenceDelay);
  }
}