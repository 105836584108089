/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-05 21:56:16
 * @LastEditors: lpj 1248708823@qq.com
 * @LastEditTime: 2022-09-14 17:41:59
 * @FilePath: \gok-portal\src\components\components-loader.js
 * @Description: 自动加载全局组件
 */

import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import scroll from 'vue-seamless-scroll'

const requireComponent =
  require.context(
    // 其组件目录的相对路径
    './gok-components',
    // 是否查询其子目录
    false,
    // 匹配基础组件文件名的正则表达式
    /gok-(.*?)\.(vue|js)$/
  )

export default {
  install(Vue) {
    /// ////////////////////////////////////////////////////////////////////////////
    /// 全局通用组件注册
    /// 通用组件注册前缀名 gok****
    /// ////////////////////////////////////////////////////////////////////////////
    requireComponent
      .keys()
      .forEach((fileName) => {
        const componentConfig =
          requireComponent(fileName)
        const componentName =
          upperFirst(
            // 获取和目录深度无关的文件名
            camelCase(
              fileName
                ?.split('/')
                ?.pop()
                ?.replace(/\.\w+$/, '')
            )
          )
        // 全局注册组件 【gok】 自动
        Vue.component(
          componentName,
          // 如果这个组件选项是通过 `export default` 导出的，
          // 那么就会优先使用 `.default`，
          // 否则回退到使用模块的根。
          componentConfig.default ||
            componentConfig
        )
      })

      
    // 注册非gok的为全局组件
    Vue.use(scroll)
  }
}
