<template>
  <div class="container flex-center" v-show="isHeaderState">
    <div class="h-full header flex-align-center">
      <div class="logo cp" @click="$router.push('/home')">
        <img class="img" :src="about.logoUrl" />
      </div>
      <div class="right flex-align-center">
        <div
          class="item flex-center center"
          :class="[ $route.path.includes(item.path) && 'item-focus']"
          v-for="(item, index) in list"
          :key="index"
          @click.stop="handleClick(item,index)"
        >

          <span class="title" v-if="!item.children">{{ item.title }}</span>
          <nav v-else id="primary_nav_wrap" class="w-full  h-full">
            <ul class="w-full h-full">
              <li class="w-full  h-full">
                <span class="title h-full" @click.prevent="">{{ item.title }}</span>
                <ul class="sub-menu">
                  <li @click.prevent.stop="handleClick(child,index,sindex)" v-for="(child,sindex) in item.children"
                      :key="child.path"><span>{{ child.title }}</span></li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
        <button class="btn ivu-btn-primary" @click="toEduPlatform">教学平台</button>
      </div>
    </div>
  </div>
</template>

<script>
import AboutModule from '@/store/modules/about/AboutModule'
import { nav } from "@config/routes-path.js"
import { getEduUrl } from '@utils/common/index.js'
// 需要显示头部导航的组件模板
const componentList = [
  {
    name:'DefaultModule',
    isShow: true
  },{
    name: 'FzNetworkInfoAdmin',
    isShow: false
  }];
export default {
  name: "gok-header",
  props: ["routes"],
  data() {
    return {
      list: [],
    };
  },
  computed:{
    about() {
      return AboutModule.about
    },
    isHeaderState(){
      return componentList.filter(item => item.name == AboutModule.about?.componentName)[0]?.isShow
    }
  },
  created() {
    this.initRoutes();
  },
  methods: {
    toEduPlatform(){
      window.open(`${getEduUrl()}?tenantId=${this.$store.state.AboutModule.about.tenantId}`,'_blank')
    },
    initRoutes() { // 针对首页header导航，目测只有两层结构树，没必要用递归，我也不想写死
      this.list = nav
      this.current = '/home'
      // let list=[]
      // for (let i = 0; i < this.routes.length; i++) {
      //   const item=this.routes[i]
      //   if (item.meta && item.meta.isHeader){
      //     list.push(item)
      //   }
      //   if (item.children){
      //     for (let i = 0; i < item.children.length; i++) {
      //       const child=item.children[i]
      //       if (child.meta && child.meta.isHeader){
      //         if (child.meta.subMenus){
      //           const newChilds= child.children.filter(item=>item.meta.isHeader)
      //           list.push({ ...child,children:newChilds })
      //         }else{
      //           list.push({ ...child,path:item.path+child.path })
      //         }
      //       }
      //     }
      //   }
      // }
      // const chunkRight= list.splice(2) // 最后两位元素兑换
      // this.list=[...list,...chunkRight.reverse()]
    },
    handleClick(item, index) {
      // console.log(item.path);
      if (item.path=='/data-visual') {
        const { href } = this.$router.resolve(item.path);
        window.open(href,'_blank')
      } else{
        this.$router.push(item.path);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@base-header-background: #020202;
@text-default-color: #9f9d9c;
@import "./menu-styles.less";

// 主样式
.container {
  width: 100%;
  height: 60px;
  background: @base-header-background;
  // opacity: 0.7;

  .header {
    width: 1200px;
  }

  .logo {
    // margin-left: 120px;
    width: 1000px;
    height: 41px;
    .img{
      //width: 500%;
      height: 41px;
      object-fit: cover;
    }
  }

  .right {
    //margin-left: 430px;
    width: 100%;
    height: 100%;

    .item {
      margin-left: 22px;
      width: 112px;
      color: @text-default-color;
      font-size: 16px;
      height: 100%;
      text-align: center;

      .sub-menu{
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }
    }

    .item:hover {
      background: @btn-hover-color;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }

    .item-focus {
      background: @btn-focus-color;
      .title{
        font-weight: bold;
        color: #fff !important;
      }
    }

    .item:nth-last-child(0) {
      margin-left: 0px;
    }


    .btn {
      width: 92px;
      height: 33px;
      background: @btn-hover-color;
      border-radius: 4px 4px 4px 4px;
      margin-left: 30px;
    }

    .btn:hover {
      cursor: pointer;
    }

  }
}
</style>
