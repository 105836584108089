/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-22 14:37:20
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-05-19 17:56:01
 * @FilePath: \gok-portal\src\store\modules\about\about.js
 * @Description: 基地基础信息模块数据层【全局共用】
 */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators'
import store from '@store/index'
import { getAboutApi, getLinksUrl } from '@api/about.js'
import { setModuleName } from '@utils/module/index.js'
@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'AboutModule'
})
class AboutModule extends VuexModule {
  about = {}
  linkList = []
  @Action
  /**
   * @description: 获取基地介绍
   * @return {*}
   */  
  async getAbout() {
    const res = await getAboutApi()
    if (res.status === 200) {
      const data = res.data;
      if (data.tenantId === '239432187215155200') window.document.title = data.name;
      setModuleName(data.componentName || 'default');
        this.SAVE_ABOUT(data || {})
    }
  }
  @Action
  /**
   * @description: 获取友情链接
   * @return {*}
   */  
  async getLinksUrl() {
    const res = await getLinksUrl()
    if (res.status === 200) {
        this.SAVE_LINKS_URL(res.data || {})
    }
  }
  
  @Mutation
  SAVE_ABOUT(about) {
    this.about = about
  }
  @Mutation
  SAVE_LINKS_URL(links){
    this.linkList = links
  }
}

export default getModule(AboutModule)
