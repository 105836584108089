<template>
  <div class="tab-box" :style="tabStyle">
    <div class="tab-items"><div class="tab-item" :class="activeTab(item)" v-for="item in list" :key="item.key" @click="tabClick(item)">{{item.name}}</div></div>
    <div v-show="showRightBtn" class="tab-btn-box">
      <div class="tab-btn-left" @click="changeTab('left')"></div>
      <div class="tab-btn-right" @click="changeTab('right')"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    list: {
      type: Array,
      required: true
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    padding: {
      type: String,
      required: false,
      default: '0'
    },
    type: {
      type: String,
      required: false,
      default: 'simple',
      validator: function (v) {
        return ['simple', 'button'].includes(v);
      }
    }
  },
  data() {
    return {
      active: ''
    }
  },
  watch: {
    value (nv, ov) {
      this.active = nv;
    }
  },
  computed: {
    tabStyle () {
      return {
        padding: this.padding
      }
    },
    activeTab () {
      return (item) => {
        return this.active === item.key ? 'tab-active' : '';
      }
    },
    showRightBtn () {
      return this.type === 'button';
    }
  },
  mounted () {
    this.active = this.value || this.list[0].key;
  },
  methods: {
    tabClick (item) {
      this.active = item.key;
      this.$emit('change', item);
    },
    changeTab (direction) {
      let index = this.list.findIndex(item => this.active === item.key);
      const listCount = this.list.length;
      direction === 'right' ? index++ : index--;
      if (index >= listCount) index = 0;
      if (index < 0) index = listCount - 1;
      this.tabClick(this.list[index]);
    }
  }
}
</script>
<style lang="less" scoped>
.tab-box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  .tab-items{
    display: flex;
  }
}
.tab-item{
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 40px;
  font-size: 30px;
  font-weight: bold;
  color: #8590A6;
  cursor: pointer;
}
.tab-item:last-child{
  margin-right: 0;
}
.tab-active{
  color: #131414;
  &:after{
    content: '';
    position: absolute;
    right: -20px;
    bottom: 0;
    z-index: -1;
    width: 70px;
    height: 24px;
    background: url(https://gok-static.goktech.cn/portal/images/icon/tab-active.png) no-repeat center;
    background-size: contain;
  }
}
.tab-btn-box{
  display: flex;
  height: 52px;
  & > div{
    width: 64px;
    height: 100%;
    border: solid 1px #457DFF;
    cursor: pointer;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .tab-btn-left{
    background-color: #457DFF;
    background-image: url(https://gok-static.goktech.cn/portal/images/icon/icon-arrow-left.png);
  }
  .tab-btn-right{
    background-color: #FFF;
    background-image: url(https://gok-static.goktech.cn/portal/images/icon/icon-arrow-right-active.png);
  }
}
</style>