/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-19 09:38:18
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-06-30 15:57:54
 * @FilePath: \gok-portal\src\utils\common\index.js
 * @Description: [通用的处理方法]
 */

/**
 * 获取文件格式/后缀名 - file.js
 * param filename {String} 包含后缀名的文件名称
 */
export const fileFormat = (filename) => {
  const tempArr = filename.split('?')[0].split('.');
  return `${tempArr[tempArr.length - 1]}`.toLowerCase();
}


/**
 * 将多行文本输入框输入的内容转换为html文本
 * 注：主要针对换行和空格做转换
 * isChangeH 是转化h2 h3 的样式
 */
 export const textareaValue2Html = (value, isChangeH = true) => {
  if (!value) return value;
  return isChangeH 
        ? value.replace(/<h2/g, '<h2 style="font-size:1.5em;font-weight:bold"').replace(/<h3/g, '<h2 style="font-size:1.17em;font-weight:bold"') 
        : value.replace(/\n/g, '<br/>').replace(/ /g, ' &nbsp');
}

/**
 * 针对wangEditor富文本内容的处理
 * 过滤reset.css中的样式设置及video标签的宽带问题
 */
export const wangEditorFormat = function (value) {
  if (!value) return '';
  return value.replaceAll('<ol', '<ol class="wang-editor-style-scoped"').replaceAll('<ul', '<ul class="wang-editor-style-scoped"').replaceAll('<video', '<video class="wang-editor-style-video"')
}

export const getEduUrl = function () {
  const curHost = window.location.host;
  return curHost.indexOf('warcy.cn') !== -1 ? process.env["VUE_APP_WARCY_EDU"] : process.env["VUE_APP_EDU"];
}
