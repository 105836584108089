/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-06 11:12:43
 * @LastEditors: lpj 1248708823@qq.com
 * @LastEditTime: 2022-09-23 15:10:02
 * @FilePath: \gok-portal\one-builder.js
 * @Description: 初始化项目配置
 */
import oneBuilder from 'gok-one'
import { Message } from 'view-design'
import Router from './router'
const route = Router.currentRoute
let one = {};
// gok-one环境
const domain = {
  // apiDomain:  'https://testapi.goktech.cn',
  apiDomain:  process.env.VUE_APP_BASE_URL,
  ucDomain: process.env.VUE_APP_HOST,
  cookieDomain: process.env.VUE_APP_COOKIE_DOMAIN
}
const storeKey = {
  token: process.env.VUE_APP_TOKEN_NAME,
  mackey: process.env.VUE_APP_MACKEY_NAME,
  tenant: process.env.VUE_APP_TENANT_NAME
}
const httpResponseErr = function (err) {
  const errData = err.response;
  // 异常信息提交
  one.errCatch.api.catchInfo(errData);
  // 鉴权过期处理
  // 401 状态中的40100/40101 gok-one中已有默认处理
  switch (errData.status) {
    case 401: break;
    case 403: Message.error('暂无权限访问,请联系管理员！');
    case 400:
      switch (errData.data.code) {
        case 999999:
          if(route.path !== '/404')
          Router.replace('/404')

      }
    default: Message.error(errData.data.message);
  }
}

// 初始化gok-one
one = oneBuilder.version(process.env.VUE_APP_VERSION)
  .env(oneBuilder.mode[process.env.VUE_APP_GOKENV])
  .setDomain(domain)
  .setTokenKey(storeKey)
  .setHttpResponseErr(httpResponseErr)
  .build()
export default one;
