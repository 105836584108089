import {
  Alert,
  Avatar,
  // Badge,
  Carousel, 
  CarouselItem,
  Button,
  ButtonGroup,
  // Breadcrumb,
  // BreadcrumbItem,
  // Cell,
  // CellGroup,
  // Col,
  // Collapse,
  // Checkbox,
  // CheckboxGroup,
  Card,
  Circle,
  // DatePicker,
  // Divider,
  // Drawer,
  // Dropdown,
  // DropdownMenu,
  // DropdownItem,
  Form,
  FormItem,
  Icon,
  Input,
  InputNumber,
  LoadingBar,
  Message,
  Modal,
  Notice,
  // Option,
  Page,
  // Panel,
  // Poptip,
  // Progress,
  // Radio,
  // RadioGroup,
  Row,
  // Scroll,
  // Select,
  Spin,
  // Switch,
  // Slider,
  // Table,
  TabPane,
  Tabs,
  Tag,
  // // TimePicker,
  // Tooltip,
  // Tree,
  // Timeline,
  // TimelineItem,
  Upload
} from 'view-design'
/**
 * @description: 按需引入iview組件
 * @param {*} Vue
 * @return {*}
 */
export default function loaderIview(Vue) {
  /**
   * @description: 全局消息
   */  
  Vue.prototype.$Message = Message;
  Vue.prototype.$Message.config({
    top: window.innerHeight / 2,
    duration: 3
  });
  Vue.prototype.$Modal = Modal;
  Vue.prototype.$Spin = Spin;
  Vue.prototype.$Notice = Notice;
  /**
   *  全局组件
   */
   Vue.component('Alert', Alert)
   Vue.component('Avatar', Avatar)
  //  Vue.component('Badge', Badge)
   Vue.component('Button', Button)
   Vue.component('ButtonGroup', ButtonGroup)
   Vue.component('Carousel', Carousel)
   Vue.component('CarouselItem', CarouselItem)
   Vue.component('TabPane', TabPane)
   Vue.component('Tabs', Tabs)
  //  Vue.component('Breadcrumb', Breadcrumb)
  //  Vue.component('BreadcrumbItem', BreadcrumbItem)
  //  Vue.component('Cell', Cell)
  //  Vue.component('CellGroup', CellGroup)
  //  Vue.component('Col', Col)
  //  Vue.component('Collapse', Collapse)
  //  Vue.component('Checkbox', Checkbox)
  //  Vue.component('CheckboxGroup', CheckboxGroup)
   Vue.component('Card', Card)
   Vue.component('i-circle', Circle)
  //  Vue.component('DatePicker', DatePicker)
  //  Vue.component('Drawer', Drawer)
  //  Vue.component('Divider', Divider)
  //  Vue.component('Dropdown', Dropdown)
  //  Vue.component('DropdownMenu', DropdownMenu)
  //  Vue.component('DropdownItem', DropdownItem)
   Vue.component('Form', Form)
   Vue.component('FormItem', FormItem)
   Vue.component('Icon', Icon)
   Vue.component('Input', Input)
   Vue.component('InputNumber', InputNumber)
   Vue.component('Modal', Modal)
   Vue.component('Notice', Notice)
   Vue.component('Page', Page)
  //  Vue.component('Poptip', Poptip)
  //  Vue.component('Progress', Progress)
   Vue.component('Row', Row)
  //  Vue.component('Scroll', Scroll)
  //  Vue.component('Select', Select)
   Vue.component('Spin', Spin)
  //  Vue.component('i-switch', Switch)
  //  Vue.component('Slider', Slider)
  //  Vue.component('TabPane', TabPane)
  //  Vue.component('Tabs', Tabs)
   Vue.component('Tag', Tag)
   Vue.component('Upload', Upload)
  //  Vue.component('Tooltip', Tooltip)
}
