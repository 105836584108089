import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@views/template/layout.vue'
import { getModuleName } from '@utils/module/index.js'
const OnlyRouterView = {
  template: '<router-view />'
}
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const moduleName = getModuleName();

export const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    redirect: '/home',
    children: [
      // 首页
      {
        path: 'home',
        meta: {
          keepAlive:false,
        },
        name: 'ProtalHome',
        component:  () => import('@views/home/index.vue')
      },
      { // 合作项目展示
        path: 'cooperation-product/:id',
        name: 'ProductDetail',
        meta: {
          hasBg: true,
        },
        component: () =>
          import(
            '@views/cooperation/cooperation-product/detail.vue'
            )
      },
      // 新闻
      {
        path: 'news',
        name: 'NewsListLayout',
        component: () =>
          import('@views/news/index.vue'),
        redirect: '/news/list',
     
        children: [
          {
            path: '/news/list',
            name: 'NewsList',
            meta: {
              hasBg: true,
            },
            component: () =>
              import(
                '@views/news/list.vue'
                )
          },
          {
            path: '/news/:id',
            component: () =>
              import(
                '@views/news/detail.vue'
                )
          },
          {
            path: '/popular-news/:id',
            component: () =>
              import(
                '@views/news/popular-detail.vue'
                )
          },
          {
            path: '/innovation-news/:id',
            component: () =>
              import(
                '@views/news/innovation-detail.vue'
                )
          },
        ]
      },
      // ---------------校企对接------------------
      {
        path: 'cooperation',
        redirect: '/cooperation/index',
        meta: {

        },
        component: () => {
          return import(
            `@views/cooperation/${moduleName === 'FzNetworkInfoAdmin' ? 'container-fzrc' : 'container'}.vue`
          )
        }
          ,
        children: [
          // 知识科普
          {
            meta: {
            },
            path: 'knowledge-popularization',
            component: () =>
              import(
                '@views/cooperation/knowledge-popularization/index.vue'
              )
          },
          // 校企合作
          {
            meta: {
              innovation: moduleName === 'FzNetworkInfoAdmin'
            },
            path: 'index',
            component: () =>
              import(
                `@views/cooperation/sp-cooperation/${moduleName === 'FzNetworkInfoAdmin' ? 'index-fzrc' : 'index'}.vue`
                // '@views/cooperation/sp-cooperation/index.vue'
              )
          },
          // 我要合作
          {
            path: 'apply-cooperation',
            meta: {

            },
            component: () =>
              import(
                '@views/cooperation/apply-cooperation/index.vue'
              )
          },
          // 合作项目展示
          {
            path: 'cooperation-product',
            meta: {
              hasBg: true,
            },
            component: () =>
              import(
                '@views/cooperation/cooperation-product/list.vue'
              )
          },
          // 合作课程
          {
            path: 'cooperation-course',
            meta: {
              hasBg: true
            },
            component: () =>
              import(
                '@views/cooperation/cooperation-course/index.vue'
              )
          },

          // 院校集群
          {
            path: 'school',
            meta: {
              hasBg: true
            },
            component: () =>
              import(
                '@views/cooperation/school/index.vue'
              )
          },

          // 企业库
          {
            path: 'company',
            meta: {
              hasBg: true,
            },
            component: () =>
              import(
                '@views/cooperation/company/index.vue'
              )
          },

          // 专家库
          {
            path: 'expert',
            meta: {
              hasBg: true,
            },
            component: () =>
              import(
                '@views/cooperation/expert/index.vue'
              )
          }
        ]
      },

      // ---------------关于基地------------------
      {
        path: 'about',
        meta: {

        },
        component: () =>
          import(
            '@views/about/index.vue'
          )
      }
    ]
  },
  // 数据大屏
  {
    path: '/data-visual',
    meta: {

    },
    component: () =>
      import('@views/data-visual/index.vue')
  },

  // {
  //   path: '/403',
  //   component: () =>
  //     import('@views/error/403/carousel.vue')
  // },
   
  // ---------------这俩路由一定要在最下面------------
  {
    path: '/404',
    meta: {

    },
    component: () =>
      import('@/views/error/404/index.vue')
  },
   // 404
   {
    path: '/*',
    meta: {

    },
    component: () =>
      import('@/views/error/404/index.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  // 自动回到最顶级
  scrollBehavior(to, from, savedPosition) {
    // isScrollTop 用于校企对接 tabs点击时过渡不顺畅问题
    return to.query.isScrollTop ? savedPosition : {
      x: 0, y: 0
    };
  }
})

export default router
