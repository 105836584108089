/*
 * @Author: shiyihuang 1023661987@qq.com
 * @Date: 2022-09-29 15:20:12
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-02-02 14:37:08
 * @FilePath: /gok-portal/src/config/routes-path.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
  const nav=[
  {
    title: "首页",
    path: "/home"
  },
  {
    title: "校企对接",
    path: "/cooperation",
    children: [
      {
        title: "知识科普",
        path: "/cooperation/knowledge-popularization"
      }, {
        title: "校企合作",
        path: "/cooperation/index"
      }, {
        title: "我要合作",
        path: "/cooperation/apply-cooperation"
      }, {
        title: "合作项目展示",
        path: "/cooperation/cooperation-product"
      }, {
        title: "合作课程展示",
        path: "/cooperation/cooperation-course"
      }, {
        title: "院校集群",
        path: "/cooperation/school"
      }, {
        title: "企业库",
        path: "/cooperation/company"
      }, {
        title: "专家库",
        path: "/cooperation/expert"
      }
    ]
  },
  {
    title: "数据大屏",
    path: "/data-visual"
  },
  {
    title: "基地介绍",
    path: "/about"
  }
]
const path = [
  ...nav,
  {
    title: "新闻资讯",
    path: "/news"
  },
];
const staticUrl = 'https://gok-static.goktech.cn/';
export {
  nav,
  path,
  staticUrl
};