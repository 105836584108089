import { get } from "./http.js";

// 关于基地
export async function getAboutApi(){
  try {
    return await get('/tac/base-portal/base-portal-info',{params:{}})
  }catch (e){
    console.error('about http is',e.message);
  }
}

// 友情链接
export async function getLinksUrl(params={}) {
  try {
    return await get("/tac/base-portal/links-url",params);
  } catch (e) {
    console.error('reports http get is',e.message);
  }
}