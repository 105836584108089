/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-05 22:19:27
 * @LastEditors: lpj 1248708823@qq.com
 * @LastEditTime: 2022-09-21 10:22:00
 * @FilePath: \gok-portal\src\directives\directives.js
 * @Description: 导入指令文件
 */
import imageErr from './modules/image-err' // 图片加载出错时的处理
import preview from './modules/preview' // 预览指令
import openHref from './modules/open-href.js' // 新标签页打开链接
import scrollReveal from './modules/scroll-reveal.js' // 新标签页打开链接
const directives = {
  imageErr,
  preview,
  openHref,
  scrollReveal
}

export default directives
