/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-05 21:35:21
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2023-02-20 11:48:39
 * @FilePath: \gok-portal\src\utils\filters\index.js
 * @Description: [全局]过滤器自动注册，太过小众的请放在组件内。自
 */
import DAYJS from 'dayjs'
/**
 * 时间戳转日期格式
 * @param {String} value
 */
export const formatDate = (value,format='') => {
  if (!value) return '';
  switch (format) {
    case "YYYY-MM-DD":
      return  DAYJS(Number(value)).format('YYYY-MM-DD')
      break;
    default:
      return  DAYJS(Number(value)).format('YYYY-MM-DD HH:mm')
      break
  }
}