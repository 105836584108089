
// 构建可跳转的href
 const createHref = function (href) {
  const TREATY = ['http://', 'https://'];
  let haveTreaty = false;
  TREATY.forEach(item => {
    if (href.indexOf(item) === 0) haveTreaty = true;
  })
  // 如果传入的域名没有协议则补充【//】否则href会携带上当前网站的域名
  return haveTreaty ? href : `//${href}`;
}
// 验证元素
const checkEl = function (el, href, modifiers) {
  const HREF_TARGET = ['_blank', 'blank', 'self', 'top', 'parent'];
  const elName = el.tagName;
  let target = HREF_TARGET[0];
  href = createHref(href);
  HREF_TARGET.forEach(item => modifiers[item] && (target = item));
  el.style.color = '#2D8cF0';
  el.style.cursor = 'pointer';
  modifiers.line ? (el.style.textDecoration = 'underline') : (el.style.textDecoration = 'none');
  // 如果是a标签设置对应属性
  if (elName === 'A') {
    el.target = `_${target}`;
    el.href = href;
  } else {
    // 不是a标签监听点击事件，打开制定域名
    el.addEventListener('click', function () {
      window.open(href, target);
    })
  }
}
export default {
  inserted: (el, binding, vnode) => {
    if(!binding.value) {
      return
    }
    checkEl(el, binding.value, binding.modifiers);
  },
  update: (el, binding, vnode) => {
    if(!binding.value) {
      return
    }
    checkEl(el, binding.value, binding.modifiers);
  }
}
