/*
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-06 11:40:46
 * @LastEditors: shiyihuang 1023661987@qq.com
 * @LastEditTime: 2022-09-14 14:47:18
 * @FilePath: \gok-portal\src\api\requerst.js
 * @Description: 请求文件 剥离请求方法
 */
import one from '@/one-builder'
const http = one.uc.http
const { get, post, put } = http.axiosProxy_

// 全局性字典 分类字典 tenantId默认1
async function getDataDicts(code,tenantId=1) {
  try {
    return  get(`/tc/dict/getDataDicts/${code}/${tenantId}`)
  }catch (e) {
    console.error('getDataDicts http get is',e.message);
  }
}

export {
  get,
  post,
  put,
  getDataDicts,
}

