<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import AboutModule from '@/store/modules/about/AboutModule'
export default {
  async created() {
   await AboutModule.getAbout()
   await AboutModule.getLinksUrl()
  }
}
</script>

<style lang="less">
#app {
  scroll-behavior: smooth;
  font-family:-apple-system,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,sans-serif !important;
  //  Mac OS X / macOS 下生效。 auto - 允许浏览器选择字体平滑的优化方式
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
