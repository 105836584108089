<!--
 * @Author: lpj 1248708823@qq.com
 * @Date: 2022-09-06 09:41:05
 * @LastEditors: sikonggpw 1327325804@qq.com
 * @LastEditTime: 2023-09-12 15:59:10
 * @FilePath: \gok-portal\src\views\template\layout.vue
 * @Description: 模板外壳布局文件
-->
<template>
 <div class="layout-container">
  <!-- 头部 -->
   <div class="header" ref="header">
     <portal-header :routes="routes"></portal-header>
   </div>
  <!-- 差异化配置需要缓存的页面 -->
    <div class="section">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" style="width: 100%"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" style="width: 100%"></router-view>
    </div>
    <!-- 底部 -->
   <div class="footer">
     <portal-footer></portal-footer>
   </div>

    <!-- 差异化配置body背景色 是否有灰色背景 -->
   <div class="mask" v-if="$route.meta.hasBg"></div>
 </div>
</template>
<script>
import PortalHeader from "@views/template/portal-header";
import PortalFooter from "@views/template/portal-footer";
import {routes} from "@router/index.js"
import { $ } from "@/utils/dom/class.js"

 export default {
   data () {
     return {
       headerScrollData:{
         scrollHeight:0,
         headerHeight:0,
       },
       routes
     }
   },
   methods:{
     handleScroll() {
       const kaydirma_cubugu =  document.documentElement.scrollTop || document.body.scrollTop;
       if (kaydirma_cubugu >= this.headerScrollData.headerHeight) {
         $.addClass(this.$refs.header,'gizle')
       } else {
         $.removeClass(this.$refs.header,'gizle')
       }

       if (kaydirma_cubugu > this.headerScrollData.scrollHeight){
         $.removeClass(this.$refs.header,'sabit')
       }else {
         $.addClass(this.$refs.header,'sabit')
       }
     },
   },
   mounted() {
     this.$nextTick(() => {
       this.headerScrollData.scrollHeight = document.documentElement.scrollTop || document.body.scrollTop; //滚动高度
       this.headerScrollData.headerHeight = this.$refs.header.clientHeight;
       window.addEventListener("scroll", this.handleScroll); // 监听（绑定）滚轮滚动事件
     });
   },
   beforeDestroy() {
     window.removeEventListener("scroll", this.handleScroll);
   },
   components: {
     PortalHeader,
     PortalFooter,
   },
 }
</script>
<style lang='less' scoped>
.layout-container{
  min-width: 1290px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    transition: top .5s;
    z-index: 999;
  }
  .gizle {
    top: -90px;
  }

  .sabit {
    top: 0;
    z-index: 9999;
  }
  .header,.footer{
    width: 100%;
  }
  .section{
    flex: 1;
    display: flex;
    //margin-top: 60px;
  }
  .mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: @bg-gray;
  }

}
</style>
